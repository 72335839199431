<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading... </span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <DxDataGrid
          v-if="can_list"
          id="work-table"
          :ref="dataGridRef"
          width="100%"
          :show-borders="true"
          :data-source="data"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :column-auto-width="true"
          :column-auto-height="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :hover-state-enabled="true"
          :word-wrap-enabled="true"
          key-expr="id"
          exporting="exportGrid"
          @row-inserted="onInserted"
          @row-updated="onUpdated"
          @row-removed="onRemoved"
          @init-new-row="initNewRow"
          @exporting="onExporting"
          @editing-start="onEditClick"
          @edit-canceling="onEditClickCancel"
          @edit-canceled="onEditClickCancel"
        >
          <DxExport
            :enabled="true"
            :visible="true"
            :allow-export-selected-data="false"
            :customize-excel-cell="customizeExcelCell"
          />
          <DxEditing
            :allow-updating="can_edit"
            :allow-adding="can_create"
            :allow-deleting="can_delete"
            mode="popup"
            :select-text-on-edit-start="true"
            :use-icons="true"
            :word-wrap-enabled="true"
          />
          <DxColumn
            type="buttons"
            :allow-exporting="false"
            :name="$t('table.actions')"
            :caption="$t('table.actions')"
          >
            <DxButton name="edit" />
            <DxButton name="delete" />
            <DxButton
              id="personnelButton"
              icon="fa fa-user"
              :on-click="onClickPersonnelButton"
            />
            <DxButton
              id="materialButton"
              icon="fa fa-archive"
              :on-click="onClickMaterialButton"
            />
            <DxButton
              id="machineButton"
              icon="fa fa-truck"
              :on-click="onClickMachineButton"
            />
            <DxButton
              id="problemButton"
              icon="fa fa-exclamation"
              :on-click="onClickProblemButton"
            />
          </DxColumn>
          <DxColumn
            data-field="work_problems"
            name=""
            caption=""
            cell-template="workProblemTemplate"
            :allow-editing="false"
            :allow-exporting="false"
            :visible="workProblemVisible"
          />
          <template #workProblemTemplate="{ data: problemData }">
            <p v-if="problemData.value.length === 0"></p>
            <img
              v-else
              src="@/assets/images/icons/shovel-yellow.png"
              class="famayellow"
              width="20px"
              height="20px"
              style="float: left"
            />
          </template>
            <DxColumn
                    data-field="id"
                    :caption="'#'"
                    :allow-editing="false"
                    :visible="false"
            />
          <DxColumn
            data-field="work_date"
            data-type="date"
            :caption="$t('table.date')"
            format="dd.MM.yyyy"
            class="bold-detail"
            :allow-editing="true"
          />
          <DxColumn
            data-field="shift"
            :name="$t('table.shift')"
            :caption="$t('table.shift')"
          />
          <DxColumn
            data-field="real_firm_id"
            :name="$t('table.company')"
            :caption="$t('table.company')"
          >
            <DxLookup
              :data-source="dataPopUpCompany"
              value-expr="real_firm_id"
              display-expr="name"
            />
          </DxColumn>
          <DxColumn
            data-field="userUser"
            :name="$t('table.user')"
            :caption="$t('table.user')"
            :allow-editing="false"
          />

          <DxColumn
            data-field="activity.wb.code"
            name="WBS #"
            caption="WBS #"
            :allow-editing="false"
            :visible="false"
          />
          <DxColumn
            data-field="wbs_id"
            :caption="$t('table.wbs_name')"
            :name="$t('table.wbs_name')"
            edit-cell-template="dropDownBoxEditor"
          >
            <DxLookup
              :data-source="dataPopUpWbsNameAll"
              value-expr="id"
              display-expr="mobile_name"
            />
          </DxColumn>

          <template #dropDownBoxEditor="{ data }">
            <DxSelectBox
              :data-source="dataPopUpWbsName"
              :value="data.data.wbs_id"
              @value-changed="(value) => setStateValue(value, data)"
              display-expr="mobile_name"
              value-expr="id"
            />
          </template>
          <DxColumn
            data-field="activity.code"
            :name="$t('table.activity#')"
            :caption="$t('table.activity#')"
            :visible="false"
            :allow-editing="false"
          />

          <template #dropDownBoxEditor2="{ data }">
            <DxSelectBox
              :data-source="subActivitiesFiltered"
              :value="data.data.activity_id"
              @value-changed="(value) => setStateValueAct(value, data)"
              display-expr="name"
              value-expr="id"
            />
          </template>
          <DxColumn
            data-field="activity_id"
            :name="$t('table.activity')"
            :caption="$t('table.activity')"
            edit-cell-template="dropDownBoxEditor2"
          >
            <DxLookup
              :data-source="getFilteredActivity"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>
          <DxColumn
            data-field="activity.responsible_position"
            :name="$t('activity.actReponsible')"
            :caption="$t('activity.actReponsible')"
            :visible="true"
            :allow-editing="false"
          >
            <DxLookup
              :data-source="positions"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>
          <DxColumn
            data-field="quantity"
            :name="$t('table.quantity')"
            :caption="$t('table.quantity')"
            format="#0.000"
            alignment="left"
          />
          <DxColumn
            data-field="activity.unit_id"
            :name="$t('table.symbol')"
            :caption="$t('table.symbol')"
            :allow-editing="false"
          >
            <DxLookup
              :data-source="dataUnit"
              unit
              value-expr="id"
              display-expr="symbol"
            />
          </DxColumn>
          <DxColumn
            data-field="not_exists_activity"
            :name="$t('table.not_exist_activity')"
            :caption="$t('table.not_exist_activity')"
          />
          <DxColumn
            data-field="not_exists_activity_unit_id"
            name="UM-NeA"
            caption="UM-NeA"
          >
            <DxLookup
              :data-source="dataPopUpUnit"
              value-expr="id"
              display-expr="symbol"
            />
          </DxColumn>
          <DxColumn :visible="true" data-field="notes">
            <DxFormItem
              :col-span="2"
              :editor-options="{ height: 100 }"
              editor-type="dxTextArea"
            />
          </DxColumn>
          <DxSummary>
            <DxGroupItem
              column="activity.wb.languages[0].wbs_translation.name"
              summary-type="count"
            />
            <DxGroupItem
              column="quantity"
              summary-type="sum"
              :align-by-column="true"
              :show-in-group-footer="true"
            />
          </DxSummary>

          <DxFilterRow :visible="true" />
          <DxSearchPanel :visible="true" />
          <DxColumnChooser :enabled="true" />
          <DxScrolling mode="standard" />
          <DxGrouping :context-menu-enabled="true" />
          <DxGroupPanel :visible="true" />

          <DxPaging :page-size="20" />

          <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="showPageSizeSelector"
            :show-info="showInfo"
            :show-navigation-buttons="showNavButtons"
          />
        </DxDataGrid>
        <h1 v-else>{{ $t('noPermission') }}</h1>
        <!-- Personnel Popup-->
        <DxPopup
          :visible="personnelPopupVisible"
          :close-on-outside-click="false"
          :show-close-button="false"
          title="Personel Bilgileri"
        >
          <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="closePersonnelPopupButton"
          />
          <div
            v-if="dataLoadingPersonnel"
            class="spinner-border m-auto"
            role="status"
          >
            <span class="sr-only">Loading... </span>
          </div>
          <div v-else style="width: 100%">
            <DxDataGrid
              id="work-table-personnel"
              ref="data-grid"
              width="100%"
              :show-borders="true"
              :data-source="dataPersonnel"
              :word-wrap-enabled="true"
              @row-updated="onUpdatedPersonel"
              @row-removed="onRemovedPersonel"
              @row-inserted="onInsertedPersonel"
              @editing-start="personnelEditClick"
              @edit-canceling="personnelEditClickCancel"
              @edit-canceled="personnelEditClickCancel"
              @init-new-row="personnelEditClick"
            >
              <DxEditing
                :allow-updating="can_edit"
                :allow-adding="can_create"
                :allow-deleting="can_delete"
                mode="row"
                :select-text-on-edit-start="true"
                :use-icons="true"
              />
              <DxColumn
                data-field="created_at"
                data-type="date"
                :caption="$t('table.date')"
                format="dd.MM.yyyy"
                class="bold-detail"
                :allow-editing="false"
              />
              <DxColumn
                :set-cell-value="setPersonnel"
                data-field="level"
                :caption="$t('table.personnel_group')"
                :visible="personelEdit"
              >
                <DxLookup
                  :data-source="
                    (levels = [
                      {
                        id: 'level1',
                        name: 'Level 1'
                      },
                      {
                        id: 'level2',
                        name: 'Level 2'
                      }
                    ])
                  "
                  value-expr="id"
                  display-expr="name"
                />
              </DxColumn>
              <DxColumn
                data-field="type_id"
                :caption="$t('table.description')"
                :set-cell-value="setPersonnelType"
                :visible="personelEdit"
              >
                <DxLookup
                  :data-source="dataPersonnelType"
                  display-expr="name"
                />
              </DxColumn>
              <DxColumn
                data-field="type"
                :caption="$t('table.type')"
                :allow-editing="false"
              />
              <DxColumn data-field="count" caption="NO" alignment="center" />
              <DxColumn
                data-field="hour"
                :caption="$t('table.hour')"
                alignment="center"
              />
              <DxColumn data-field="notes" :caption="$t('table.notes')" />
            </DxDataGrid>
          </div>
        </DxPopup>
        <!-- Material Popup-->
        <DxPopup
          :visible="materialPopupVisible"
          :close-on-outside-click="false"
          :show-close-button="false"
          title="Malzeme Bilgileri"
        >
          <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="closeMaterialPopupButton"
          />
          <div
            v-if="dataLoadingMaterial"
            class="spinner-border m-auto"
            role="status"
          >
            <span class="sr-only">Loading... </span>
          </div>
          <div v-else style="width: 100%">
            <DxDataGrid
              id="work-table-material"
              ref="data-grid"
              width="100%"
              :show-borders="true"
              :data-source="dataMaterial"
              :word-wrap-enabled="true"
              @row-updated="onUpdatedMaterial"
              @row-removed="onRemovedMaterial"
              @row-inserted="onInsertedMaterial"
            >
              <DxEditing
                :allow-updating="can_edit"
                :allow-adding="can_create"
                :allow-deleting="can_delete"
                mode="row"
                :select-text-on-edit-start="true"
                :use-icons="true"
                start-edit-action="dblClick"
              />
              <DxColumn
                data-field="created_at"
                data-type="date"
                :caption="$t('table.date')"
                format="dd.MM.yyyy"
                class="bold-detail"
                :allow-editing="false"
              />
              <DxColumn
                :set-cell-value="setMaterial"
                data-field="group_id"
                :caption="$t('table.material_group')"
              >
                <DxLookup
                  :data-source="dataEditMaterialGroup"
                  value-expr="id"
                  display-expr="name"
                />
              </DxColumn>
              <DxColumn
                data-field="material_id"
                :caption="$t('table.material')"
                :set-cell-value="setMaterialCode"
              >
                <!--                  kapoğlu-->
                <DxLookup
                  :data-source="getFilteredMaterial"
                  value-expr="id"
                  display-expr="name"
                />
              </DxColumn>
              <DxColumn
                data-field="material.unit_id"
                :name="$t('table.symbol')"
                :caption="$t('table.symbol')"
                :allow-editing="false"
              >
                <DxLookup
                  :data-source="dataUnit"
                  value-expr="id"
                  display-expr="symbol"
                />
              </DxColumn>
              <DxColumn
                data-field="quantity"
                :caption="$t('table.quantity')"
                format="#.000"
              />
              <DxColumn
                data-field="description"
                :caption="$t('table.description')"
              >
                <DxLookup
                  :data-source="
                    (levels = [
                      {
                        id: 'Used',
                        name: 'Used'
                      },
                      {
                        id: 'Borrow',
                        name: 'Borrow'
                      },
                      {
                        id: 'In',
                        name: 'In'
                      },
                      {
                        id: 'Stolen',
                        name: 'Stolen'
                      },
                      {
                        id: 'Expired',
                        name: 'Expired'
                      },
                      {
                        id: 'Unsuitable',
                        name: 'Unsuitable'
                      }
                    ])
                  "
                  value-expr="id"
                  display-expr="name"
                />
              </DxColumn>
              <DxColumn data-field="notes" :caption="$t('table.notes')" />
            </DxDataGrid>
          </div>
        </DxPopup>
        <!-- Machine Popup-->
        <DxPopup
          :visible="machinePopupVisible"
          :close-on-outside-click="false"
          :show-close-button="false"
          title="Makine Bilgileri"
        >
          <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="closeMachinePopupButton"
          />
          <div
            v-if="dataLoadingMachine"
            class="spinner-border m-auto"
            role="status"
          >
            <span class="sr-only">Loading... </span>
          </div>
          <div v-else style="width: 100%">
            <DxDataGrid
              id="work-table-machine"
              ref="data-grid"
              width="100%"
              :show-borders="true"
              :word-wrap-enabled="true"
              :data-source="dataMachine"
              @row-updated="onUpdatedMachine"
              @row-removed="onRemovedMachine"
              @row-inserted="onInsertedMachine"
              @editing-start="machineEditClick"
              @edit-canceling="machineEditClickCancel"
              @edit-canceled="machineEditClickCancel"
              @init-new-row="machineEditClick"
            >
              <DxEditing
                :allow-updating="can_edit"
                :allow-adding="can_create"
                :allow-deleting="can_delete"
                mode="row"
                :select-text-on-edit-start="true"
                :use-icons="true"
                start-edit-action="dblClick"
              />
              <DxColumn
                data-field="created_at"
                data-type="date"
                :caption="$t('table.date')"
                format="dd.MM.yyyy"
                class="bold-detail"
                :allow-editing="false"
              />
              <DxColumn
                :set-cell-value="setMachine"
                data-field="level"
                :caption="$t('table.machine_group')"
                :visible="machineEdit"
              >
                <DxLookup
                  :data-source="
                    (levels = [
                      {
                        id: 'level1',
                        name: 'Level 1'
                      },
                      {
                        id: 'level2',
                        name: 'Level 2'
                      }
                    ])
                  "
                  value-expr="id"
                  display-expr="name"
                />
              </DxColumn>
              <DxColumn
                data-field="type_id"
                :caption="$t('table.machine')"
                :set-cell-value="setMachineType"
                :visible="machineEdit"
              >
                <DxLookup :data-source="dataMachineType" display-expr="name" />
              </DxColumn>
              <DxColumn
                data-field="type"
                :caption="$t('table.type')"
                :allow-editing="false"
              />
              <DxColumn data-field="count" caption="NO" alignment="center" />
              <DxColumn
                data-field="hour"
                :caption="$t('table.hour')"
                alignment="center"
              />
              <DxColumn data-field="notes" :caption="$t('table.notes')" />
            </DxDataGrid>
          </div>
        </DxPopup>
        <!-- Problem Popup-->
        <DxPopup
          :visible="problemPopupVisible"
          :close-on-outside-click="false"
          :show-close-button="false"
          title="Problem Bilgileri"
        >
          <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="closeProblemPopupButton"
          />
          <div
            v-if="dataLoadingProblem"
            class="spinner-border m-auto"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div v-else style="width: 100%">
            <DxDataGrid
              id="work-table-problem"
              ref="data-grid"
              width="100%"
              :show-borders="true"
              :data-source="dataProblem"
              :word-wrap-enabled="true"
              @row-updated="onUpdatedProblem"
              @row-removed="onRemovedProblem"
              @row-inserted="onInsertedProblem"
            >
              <DxEditing
                :allow-updating="can_edit"
                :allow-adding="can_create"
                :allow-deleting="can_delete"
                mode="row"
                :select-text-on-edit-start="true"
                :use-icons="true"
                start-edit-action="dblClick"
              />
              <DxColumn
                data-field="created_at"
                data-type="date"
                :caption="$t('table.date')"
                format="dd.MM.yyyy"
                class="bold-detail"
                :allow-editing="false"
              />
              <DxColumn
                :set-cell-value="setProblem"
                data-field="type_id"
                :caption="$t('table.problem_group')"
              >
                <DxLookup
                  :data-source="dataProblemGroup"
                  value-expr="id"
                  display-expr="name"
                />
              </DxColumn>
              <DxColumn
                data-field="problem_id"
                caption="Problem"
                :set-cell-value="setProblemCode"
              >
                <DxLookup
                  :data-source="getFilteredProblem"
                  value-expr="id"
                  display-expr="name"
                />
              </DxColumn>
              <DxColumn
                data-field="explanation"
                :caption="$t('table.explanation')"
              />
              <DxColumn data-field="actions" :caption="$t('table.actions')" />
              <DxColumn
                data-field="is_resolved"
                :caption="$t('table.is_resolved')"
              />
              <DxColumn
                data-field="resolved_at"
                data-type="date"
                :caption="$t('table.resolved_at')"
                format="dd.MM.yyyy"
                :allow-editing="false"
              />
            </DxDataGrid>
          </div>
        </DxPopup>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from '@axios';
import { BCard, BDropdown, BDropdownItem } from 'bootstrap-vue';
import 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
import {
  DxDataGrid,
  DxColumn,
  DxColumnChooser,
  DxFilterRow,
  DxSearchPanel,
  DxLookup,
  DxScrolling,
  DxSummary,
  DxFormItem,
  DxGroupItem,
  DxGroupPanel,
  DxGrouping,
  DxEditing,
  DxPaging,
  DxButton,
  DxPager,
  DxExport
} from 'devextreme-vue/data-grid';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { readOnly } from '@/auth/utils';

let activityListF = [];
let materialListF = [];
let problemListF = [];
const dataGridRef = 'dataGrid';

export default {
  components: {
    BCard,
    DxButton,
    DxSelectBox,
    DxExport,
    DxPager,
    DxDataGrid,
    DxColumn,
    DxFormItem,
    DxColumnChooser,
    DxFilterRow,
    DxLookup,
    DxSearchPanel,
    DxScrolling,
    DxSummary,
    DxGroupItem,
    DxGroupPanel,
    DxGrouping,
    DxEditing,
    DxPaging,
    DxPopup,
    DxToolbarItem
  },
  data() {
    return {
      readOnly: false,
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      displayModes: [
        { text: "Display Mode 'full'", value: 'full' },
        { text: "Display Mode 'compact'", value: 'compact' }
      ],
      displayMode: 'full',

      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      selectedLanguage: {},

      dataGridRef,
      data: [],
      positions: [],
      dataUnit: [],
      dataPersonnel: [],
      dataMaterial: [],
      dataMachine: [],
      dataProblem: [],

      m_work_id: 0,
      ma_work_id: 0,
      prb_work_id: 0,
      persn_work_id: 0,
      //popup
      dataPopUpWbsName: [],
      dataPopUpWbsNameAll: [],
      dataPopUpActivityName: [],
      dataPopUpUnit: [],
      dataPopUpCompany: [],

      //materialEdit
      dataEditMaterial: [],
      dataEditMaterialGroup: [],

      //machine
      dataMachineType: [],
      machineEdit: false,

      //problem
      dataProblemGroup: [],
      dataProblemType: [],

      //personnel
      dataPersonnelType: [],
      personelEdit: false,

      dataLoading: true,
      dataLoadingPersonnel: true,
      dataLoadingMaterial: true,
      dataLoadingMachine: true,
      dataLoadingProblem: true,
      error: '',
      personnelPopupVisible: false,
      workProblemVisible: true,
      materialPopupVisible: false,
      machinePopupVisible: false,
      problemPopupVisible: false,
      selectedWbs: null,
      subActivities: [],
      subActivitiesFiltered: [],
      setActivityCode(rowData, value) {
        this.defaultSetCellValue(rowData, value);
        rowData.activity_id = value;
      },
      setMaterialCode(rowData, value) {
        // this.defaultSetCellValue(rowData, value);
        rowData.material_id = value;
      },
      setMaterial(rowData, value) {
        rowData.material_id = null;
        rowData.group_id = value;
      },
      setProblem(rowData, value) {
        this.defaultSetCellValue(rowData, value);
        rowData.problem_id = null;
        rowData.type_id = value;
      },
      setProblemCode(rowData, value) {
        this.defaultSetCellValue(rowData, value);
        rowData.problem_id = value;
      },
      setMachineType(rowData, value) {
        this.defaultSetCellValue(rowData, value);

        rowData.type = rowData.type_id.name;
        rowData.type_id = rowData.type_id.id;
      },
      setPersonnelType(rowData, value) {
        this.defaultSetCellValue(rowData, value);

        rowData.type = rowData.type_id.name;
        rowData.type_id = rowData.type_id.id;
      },
      closePersonnelPopupButton: {
        text: 'Close',
        onClick: () => {
          this.personnelPopupVisible = false;
        }
      },
      closeMaterialPopupButton: {
        text: 'Close',
        onClick: () => {
          this.materialPopupVisible = false;
        }
      },
      closeMachinePopupButton: {
        text: 'Close',
        onClick: () => {
          this.machinePopupVisible = false;
        }
      },
      closeProblemPopupButton: {
        text: 'Close',
        onClick: () => {
          this.problemPopupVisible = false;
        }
      }
    };
  },
  computed: {
    dataGrid: function () {
      return this.$refs[dataGridRef].instance;
    }
  },
  async mounted() {
    this.readOnly = readOnly();

    await this.getPageAuths();
    await this.getPositions();
    await this.getLanguage();
    await this.getWbsAll();
    await this.getDatas();

    this.refreshData();
  },
  methods: {
    setStateValueV2(rowData, value) {
      rowData.activity_id = null;
      rowData.wbs_id = value;
    },
    setStateValue(rowData, v) {
      v.setValue(rowData.value);
      this.subActivitiesFiltered = this.subActivities.filter(
        (l) => l.wbs_id == rowData.value
      );
    },
    setStateValueAct(rowData, v) {
      v.setValue(rowData.value);
      // this.subActivitiesFiltered = this.subActivities.filter(l => l.wbs_id == rowData.value)
    },

    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-auth', { page: 'Work' });
      this.can_edit = this.readOnly && pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = this.readOnly && pageAuths.data.can_delete;
      this.can_create = this.readOnly && pageAuths.data.can_create;
    },
    async getPositions() {
      this.error = '';
      const response = await axios.get('/list-position');

      try {
        this.positions = response?.data || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
          (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
      this.getDatas();
    },
    async getActivityList() {
      this.error = '';
      const response = await axios.get('/list-activity-dash');

      try {
        this.dataPopUpActivityName = response?.data?.result || [];
        activityListF = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUnit() {
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataUnit = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    closeAllMasterDetail() {
      this.$refs['dataGrid'].instance.collapseAll(-1);
    },
    clearSelection() {
      const dataGrid = this.$refs[this.dataGridRefName].instance;

      dataGrid.clearSelection();
    },
    getFilteredActivity: (options) => {
      return {
        store: activityListF,
        filter: options.data ? ['wbs_id', '=', options.data.wbs_id] : null
      };
    },
    getFilteredMaterial: (options) => ({
      store: materialListF,
      filter: options.data ? ['group_id', '=', options.data.group_id] : null
    }),
    getFilteredProblem: (options) => ({
      store: problemListF,
      filter: options.data ? ['type_id', '=', options.data.type_id] : null
    }),
    async getWbsAll() {
      this.error = '';
      const response = await axios.get('/list-wbs?noHi=true');

      try {
        this.dataPopUpWbsNameAll = response?.data?.result || [];
        this.dataPopUpWbsNameAll.forEach((f) => {
          f.activities.forEach((d) => {
            this.subActivities.push({
              id: d.id,
              wbs_id: f.id,
              name: d.languages[0]?.activity_translation?.name || ''
            });
          });
        });
        this.subActivitiesFiltered = this.subActivities;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getWbsName() {
      this.error = '';
      const response = await axios.get('/list-wbs');

      try {
        this.dataPopUpWbsName = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getMaterial() {
      this.error = '';
      const response = await axios.get('/list-material/');

      try {
        this.dataEditMaterial = response?.data?.result || [];
        materialListF = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getProblemGroup() {
      this.error = '';
      const response = await axios.get('/list-problem-type');

      try {
        this.dataProblemGroup = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getMaterialGroup() {
      this.error = '';
      const response = await axios.get('/list-material-group');

      try {
        this.dataEditMaterialGroup = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getProblemType() {
      this.error = '';
      const response = await axios.get('/list-problems');

      try {
        this.dataProblemType = response?.data?.result || [];
        problemListF = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getPersonnelType(levelValue) {
      this.error = '';
      let get = '';
      levelValue == 'level1'
        ? (get = '/list-personnel-group')
        : (get = '/list-personnel');

      const response = await axios.get(get);

      try {
        this.dataPersonnelType = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getMachineType(levelValue) {
      this.error = '';
      let get = '';
      levelValue == 'level1'
        ? (get = '/list-machine-group')
        : (get = '/list-machine');

      const response = await axios.get(get);

      try {
        this.dataMachineType = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getCompanyName() {
      this.error = '';
      const response = await axios.get('/list-sub-firm');

      try {
        this.dataPopUpCompany = response?.data?.result || [];
        this.dataPopUpCompany.forEach((d) => {
          if (d.isMainFirm) {
            d.real_firm_id = 'm' + d.id;
          } else {
            d.real_firm_id = 't' + d.id;
          }
        });
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUnitName() {
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataPopUpUnit = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    setMachine(rowData, value) {
      rowData.level = value;
      this.getMachineType(value);
    },
    setPersonnel(rowData, value) {
      rowData.level = value;
      this.getPersonnelType(value);
    },
    personnelEditClick() {
      this.personelEdit = true;
    },
    personnelEditClickCancel() {
      this.personelEdit = false;
    },
    onEditClick(e) {
      this.subActivitiesFiltered = this.subActivities.filter(
        (l) => l.wbs_id == e.data.wbs_id
      );
      this.workProblemVisible = false;
    },
    onEditClickCancel() {
      this.workProblemVisible = true;
    },
    machineEditClick() {
      this.machineEdit = true;
    },
    machineEditClickCancel() {
      this.machineEdit = false;
    },

    async getDatas() {
      await this.getActivityList();
      await this.getUnit();
      await this.getWbsName();
      await this.getUnitName();
      await this.getCompanyName();
      await this.getMaterialGroup();
      await this.getProblemGroup();
      await this.getMaterial();
      await this.getProblemType();
    },
    refreshData() {
      this.dataLoading = true;
      this.error = '';
      axios
        .get('/list-work?noPhoto=true')
        .then((response) => {
          if (response.status === 200) {
            let users = response?.data?.users || [];
            let pos = response?.data?.pos || [];
            let rp = response?.data?.rp || [];
            this.data = response?.data?.result || [];
            this.data.forEach((d) => {
              if (d.is_main_firm) {
                d.real_firm_id = 'm' + d.firm_id;
              } else {
                d.real_firm_id = 't' + d.sub_firm_id;
              }
            });
            this.dataLoading = false;
          } else {
            this.error = response?.data?.message || 'Unexpected API error';
            this.dataLoading = false;
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
          this.dataLoading = false;
        });
    },
    showToast({ variant = 'success', title, text, icon = 'XIcon' }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('FamaMetric-Works');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          if (
            options.gridCell.rowType === 'data' &&
            options.gridCell.column.dataField === 'user'
          ) {
            options.gridCell.value =
              options.gridCell.value.firstname +
              ' ' +
              options.gridCell.value.lastname;

            options.gridCell.column.dataType = 'string';

            options.excelCell._value.model.value = options.gridCell.value;

            //options.gridCell.column.dataField.value ='asd';
            // options.value = 'asd';
          }

          const excelCell = options;
          excelCell.font = { name: 'Arial', size: 12 };
          excelCell.alignment = { horizontal: 'left' };
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Fama-Works.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    exportGrid() {
      const doc = new jsPDF({
        orientation: 'landscape'
      });
      doc.setFont('Times');
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: this.dataGrid,
        keepColumnWidths: true
      }).then(() => {
        // doc.addFileToVFS("MyFont.ttf", myFont);

        doc.save('Fama-Works.pdf');
      });
    },
    customizeExcelCell(options) {
      var gridCell = options.gridCell;

      if (!gridCell) {
        return;
      }

      if (gridCell.rowType === 'data') {
        if (gridCell.data.OrderDate < new Date(2014, 2, 3)) {
          options.font.color = '#AAAAAA';
        }
        if (gridCell.data.SaleAmount > 15000) {
          if (gridCell.column.dataField === 'OrderNumber') {
            options.font.bold = true;
          }
          if (gridCell.column.dataField === 'SaleAmount') {
            options.backgroundColor = '#FFBB00';
            options.font.color = '#000000';
          }
        }
      }

      if (gridCell.rowType === 'group') {
        if (gridCell.groupIndex === 0) {
          options.backgroundColor = '#BEDFE6';
        }
        if (gridCell.groupIndex === 1) {
          options.backgroundColor = '#C9ECD7';
        }
        if (gridCell.column.dataField === 'Employee') {
          options.value = `${gridCell.value} (${gridCell.groupSummaryItems[0].value} items)`;
          options.font.bold = false;
        }
        if (gridCell.column.dataField === 'SaleAmount') {
          options.value = gridCell.groupSummaryItems[0].value;
          options.numberFormat = '&quot;Max: &quot;$0.00';
        }
      }

      if (
        gridCell.rowType === 'groupFooter' &&
        gridCell.column.dataField === 'SaleAmount'
      ) {
        options.value = gridCell.value;
        options.numberFormat = '&quot;Sum: &quot;$0.00';
        options.font.italic = true;
      }

      if (
        gridCell.rowType === 'totalFooter' &&
        gridCell.column.dataField === 'SaleAmount'
      ) {
        options.value = gridCell.value;
        options.numberFormat = '&quot;Total Sum: &quot;$0.00';
      }
    },
    onRemoved(e) {
      axios
        .post(`delete-work/${e.data.id}`, {})
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla silindi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onRemovedProblem(e) {
      axios
        .post(`delete-work-problem/${e.data.id}`, {})
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla silindi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onRemovedMachine(e) {
      axios
        .post(`delete-work-machine/${e.data.id}`, {})
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla silindi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onRemovedPersonel(e) {
      axios
        .post(`delete-work-personnel/${e.data.id}`, {})
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla silindi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onRemovedMaterial(e) {
      axios
        .post(`delete-work-material/${e.data.id}`, {})
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla silindi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onUpdated(e) {
      const activity_id = e.data.activity_id;

      let {
        work_date,
        shift,
        quantity,
        firm_id,
        not_exists_activity,
        not_exists_activity_unit_id,
        notes,
        real_firm_id
      } = e.data;
      let is_main_firm = true;
      if (real_firm_id.toString().startsWith('t')) is_main_firm = false;
      firm_id = real_firm_id.toString().replaceAll('t', '').replaceAll('m', '');
      axios
        .post(`edit-work/${e.data.guid}`, {
          work_date,
          activity_id,
          shift,
          quantity,
          firm_id,
          not_exists_activity,
          not_exists_activity_unit_id,
          notes,
          is_main_firm
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla güncellendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onUpdatedProblem(e) {
      const { problem_id, explanation, is_resolved, actions } = e.data;
      axios
        .post(`edit-work-problem/${e.data.id}`, {
          problem_id,
          explanation,
          actions,
          is_resolved
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla güncellendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onUpdatedPersonel(e) {
      const { type, type_id, count, hour, notes, level } = e.data;
      axios
        .post(`edit-work-personnel/${e.data.id}`, {
          type,
          type_id,
          count,
          hour,
          notes,
          level
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla güncellendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onUpdatedMachine(e) {
      const { type, type_id, count, hour, notes, level } = e.data;
      axios
        .post(`edit-work-machine/${e.data.id}`, {
          type,
          type_id,
          count,
          hour,
          notes,
          level
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla güncellendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onUpdatedMaterial(e) {
      const { material_id, quantity, description, notes } = e.data;
      axios
        .post(`edit-work-material/${e.data.id}`, {
          material_id,
          quantity,
          description,
          notes
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla güncellendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    initNewRow(e) {
      e.data.work_problems = [];
      e.data.user = '';
    },
    onInserted(e) {
      const activity_id = e.data.activity_id;

      //work_problems,work_date,shift,firm_id, user, responsible,activity.wb.code,wbs_id,activity.code,activity_id,quantity
      let {
        work_date,
        shift,
        quantity,
        firm_id,
        isMainFirm,
        not_exists_activity,
        not_exists_activity_unit_id,
        notes,
        real_firm_id
      } = e.data;

      let is_main_firm = true;
      if (real_firm_id.toString().startsWith('t')) is_main_firm = false;
      firm_id = real_firm_id.toString().replaceAll('t', '').replaceAll('m', '');

      axios
        .post(`create-work`, {
          activity_id,
          work_date,
          shift,
          quantity,
          firm_id,
          isMainFirm: is_main_firm,
          not_exists_activity,
          not_exists_activity_unit_id,
          notes
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla eklendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onInsertedPersonel(e) {
      const work_id = this.persn_work_id;

      const { type, type_id, count, hour, notes, level } = e.data;
      axios
        .post(`create-work-personnel`, {
          work_id,
          type,
          type_id,
          count,
          hour,
          notes,
          level
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla eklendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onInsertedMachine(e) {
      const work_id = this.ma_work_id;

      const { type, type_id, count, hour, notes, level } = e.data;
      axios
        .post(`create-work-machine`, {
          work_id,
          type,
          type_id,
          count,
          hour,
          notes,
          level
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla eklendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onInsertedProblem(e) {
      const work_id = this.prb_work_id;

      const { explanation, problem_id, is_resolved, actions } = e.data;
      axios
        .post(`create-work-problem`, {
          work_id,
          problem_id,
          explanation,
          is_resolved,
          actions
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla eklendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onInsertedMaterial(e) {
      const work_id = this.m_work_id;

      const { material_id, quantity, description, notes } = e.data;
      axios
        .post(`create-work-material`, {
          work_id,
          material_id,
          quantity,
          description,
          notes
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla eklendi.'
          });
          this.refreshData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    async onClickPersonnelButton(e) {
      this.personnelPopupVisible = true;
      this.persn_work_id = e.row.data.id;

      axios
        .get(`list-work-personnel/${e.row.data.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.dataPersonnel = response?.data?.result || [];
            this.dataLoadingPersonnel = false;
          } else {
            this.error = response?.data?.message || 'Unexpected API error';
            this.dataLoadingPersonnel = false;
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
          this.dataLoadingPersonnel = false;
        });
    },
    async onClickMaterialButton(e) {
      this.materialPopupVisible = true;
      this.m_work_id = e.row.data.id;

      axios
        .get(`list-work-material/${e.row.data.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.dataMaterial = response?.data?.result || [];
            this.dataLoadingMaterial = false;
          } else {
            this.error = response?.data?.message || 'Unexpected API error';
            this.dataLoadingMaterial = false;
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
          this.dataLoadingMaterial = false;
        });
    },
    async onClickMachineButton(e) {
      this.machinePopupVisible = true;
      this.ma_work_id = e.row.data.id;

      axios
        .get(`list-work-machine/${e.row.data.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.dataMachine = response?.data?.result || [];
            this.dataLoadingMachine = false;
          } else {
            this.error = response?.data?.message || 'Unexpected API error';
            this.dataLoadingMachine = false;
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
          this.dataLoadingMachine = false;
        });
    },
    async onClickProblemButton(e) {
      this.problemPopupVisible = true;
      this.prb_work_id = e.row.data.id;

      axios
        .get(`list-work-problem/${e.row.data.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.dataProblem = response?.data?.result || [];
            this.dataLoadingProblem = false;
          } else {
            this.error = response?.data?.message || 'Unexpected API error';
            this.dataLoadingProblem = false;
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
          this.dataLoadingProblem = false;
        });
    }
  }
};
</script>

<style lang="scss">
@import './tables.scss';

.dx-master-detail-cell {
  background-color: #ffc107 !important;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
  background-color: #ffc107;
  color: #fff;
  border-bottom: none;
}

.dx-state-readonly {
  background: #ededed !important;
}

.table_grid_top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.dx-datagrid .dx-link {
  color: #009fb1 !important;
}

.table_grid_top #exportButton {
  margin-right: 10px;
}

.fama-table > input {
  width: auto;
}
</style>
